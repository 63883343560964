/* eslint-disable arrow-body-style */
import i18next from 'i18next';
import qs from 'qs';

import startCase from 'lodash/startCase';
import toUpper from 'lodash/toUpper';
import toLower from 'lodash/toLower';
import upperCase from 'lodash/upperCase';
import compact from 'lodash/compact';
import get from 'lodash/get';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import uniqBy from 'lodash/uniqBy';
import unionBy from 'lodash/unionBy';
import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';
import { closestMatch } from 'closest-match';

import {
  BuyHuntFilterProps,
  DefaultOptionProps,
  FacetStatsProps,
  FilterProps,
  GroupSettingProps,
  QSFilterProps,
  QSUrlProps,
  SettingProps,
} from '@design-system/components/FiltersV1/FilterProps';

import useAlgoliaService from '@source/hooks/useAlgoliaService';
import formatNumber, { deepCopy, getCustomLabel, isDotNotation } from '@design-system/utils/utils';
import { FilterConfigProps } from '@source/pages/Home/components/Filters/configs';
import {
  RANGE_FILTER_CONFIG,
  NUMBER_FILTER_CONFIG,
  BUTTON_FILTER_CONFIG,
  BOOLEAN_FILTER_CONFIG,
  mapFilterNameFromQS,
  // CHECK_BOX_FILTER_CONFIG,
  CARRO_EXCLUSIVES_FILTER_URL_CONFIG,
  AVAILABILITY_FILTER_URL_CONFIG,
  BUTTON_FILTER_CONFIG_HK,
} from '@source/pages/Home/components/Filters/configs/constants';
import Logger from '@source/services/Logger';
import { CarroExclusivesTag } from '@source/redux/slice/browseSlice';

/**
 * Check the filter has been selected or not
 *
 * @param filter : ;
 * @returns
 */
export const hasSelectedValues = (filter: FilterProps) =>
  filter?.selectedBoolean ||
  filter?.selectedMin ||
  filter.selectedMax ||
  filter.selectedMinInput ||
  filter.selectedMaxInput ||
  filter.selectedNumber ||
  (filter.selectedOptions && filter.selectedOptions.length > 0);

export const hasMultiSelectValues = (filter: FilterProps) =>
  filter.selectedOptions && filter.selectedOptions.length > 1;

export const hasActiveFlag = (filters: FilterProps[]) =>
  filters?.some((item: FilterProps) => item?.activeFlag === true);

export const getSelectedFiltersCount = (settings: SettingProps[], ignoreMakeCount?: boolean): number =>
  settings.reduce((totalCount, currentSetting) => {
    let newCount = 0;
    currentSetting?.filters?.forEach((f: FilterProps) => {
      if (ignoreMakeCount && currentSetting.name === 'make') {
        newCount += 0; // Don't count when its make and ignore make count
      } else if (f?.selectedOptions && f.selectedOptions.length > 0) {
        newCount += f?.selectedOptions?.length || 0;
      } else if (hasSelectedValues(f)) {
        newCount += 1;
      }
    });

    return totalCount + newCount;
  }, 0);

export const mapSettingData = (listName: any, listData: any, isDesktop?: boolean) =>
  listName
    .map((df: string) => listData?.find((cfs: SettingProps) => cfs?.name === df))
    .filter((i: any) => i) // clear undefined items
    .filter((s: any) => (isDesktop ? s.active : s.activeMobile))
    .filter((s: any) => {
      if (s?.showOnFacetsAttribute) {
        return s?.filters?.every((filter: FilterProps) => !!filter.facets?.[s?.showOnFacetsAttribute]);
      }

      return true;
    });

// /**
//  * Converted label based on the custom label pattern
//  *
//  * @param customLabel
//  * @param item
//  * @returns
//  */
// export const getCustomLabel = (customLabel: string, item: any) => {
//   if (!customLabel) return;

//   const regex = /\${([^$]+)\}/gm;
//   const results = customLabel.match(regex) || [];
//   // eslint-disable-next-line consistent-return
//   return results?.reduce((customStr, matchString) => {
//     const args = new RegExp(regex).exec(matchString) || [];
//     return customStr.replace(matchString, item[args?.[1]]);
//   }, customLabel);
// };

export const transformFiltersToMap = (
  obj: { name?: string; filters?: FilterProps[]; [name: string]: unknown },
  currentFilter: { name?: string; filters?: FilterProps[]; [name: string]: unknown },
) =>
  ({
    ...obj,
    [currentFilter?.name || '']: {
      ...currentFilter,
    },
  } as Record<string, SettingProps>);

export const convertPayloadHasOptionToArray = (payloads: FilterProps) =>
  Object.keys(payloads)
    .map((k: string) => get(payloads, k) as SettingProps)
    ?.filter((item: SettingProps) => item?.filters?.some((i: FilterProps) => i?.selectedOptions?.length));

export const convertObjToArray = (payloads: Record<string, SettingProps> | Record<string, GroupSettingProps>) =>
  Object.keys(payloads).map((k: string) => get(payloads, k));

/**
 * Get number of selected options base on fitler type
 *
 * @param filter
 * @returns
 */
export const numberOfSelectedOptions = (filter: FilterProps) => {
  switch (filter?.type) {
    case 'range':
      return filter.selectedMaxInput || filter.selectedMinInput ? 1 : 0;
    case 'button':
    case 'select':
    case 'dropdown':
    case 'make_model':
      return filter.selectedOptions?.length || 0;
    case 'checkbox':
    case 'number':
      return filter.selectedNumber ? 1 : 0;
    case 'range_input':
      return filter.selectedMaxInput || filter.selectedMinInput ? 1 : 0;
    case 'boolean':
      return filter.selectedBoolean ? 1 : 0;
    default:
      return 0;
  }
};

export const useBrandModelUtil = () => {
  const { fetchAlgoliaData } = useAlgoliaService();

  const fetchMakesModels = async ({
    filters,
    query = '',
    facetsToRetrieve,
    sortBy,
  }: {
    filters?: string;
    query?: string;
    facetsToRetrieve?: string[];
    sortBy?: { field: string; order: string };
  }) => {
    const params = {
      facets: facetsToRetrieve,
      filters,
    };
    let facetsResults = {} as Record<string, any>;

    try {
      const result = await fetchAlgoliaData(params, sortBy, query);
      const { facets } = result || {};
      facetsResults = Object.entries(facets || {}).reduce(
        (facetsObj, currentFacet) => ({
          ...facetsObj,
          [currentFacet[0]]: Object.keys(currentFacet[1]),
        }),
        {},
      ) as Record<string, any>;
    } catch (err) {
      Logger.error(err);
    }

    return facetsResults;
  };

  return {
    fetchMakesModels,
  };
};

/**
 * Get options from algolia facets
 *
 * eg: facets:
 * inventory.transmission {
    "automatic": 200,
    "manual": 16
    }
 *
 * @param facets
 * @param facetName
 * @param filterOptions
 *
 * @returns
 */

export const getOptionsInFacets = (facets: any, facetName: string, filterOptions?: any) => {
  let facetValues = facets[facetName] ? Object.keys(facets[facetName]) : [];
  facetValues.sort();

  if (facetName === 'promotion_name' && facets[facetName]) {
    facetValues = Object.entries(facets[facetName])
      .sort(([, a], [, b]) => (b as number) - (a as number))
      .map((item) => item[0]);
  }

  switch (facetName) {
    case 'listing.is_good_deal':
      return facetValues.includes('1') ? [{ label: 'Good Deal', value: 1 }] : [];
    case 'listing.is_urgent_sale':
      return facetValues.includes('1') ? [{ label: 'Urgent Sale', value: 1 }] : [];
    case 'inventory.number_of_owners':
      return facetValues.length >= filterOptions.length
        ? filterOptions
        : facetValues.map((value) => ({
            label: parseInt(value, 10) === 0 ? 'No' : 'Yes',
            value,
          }));
    case 'listing.is_coe_renewal':
      return facetValues.map((value) => ({
        label: parseInt(value, 10) ? 'No' : 'Yes',
        value,
      }));
    case 'listing.is_opc':
      return facetValues.map((value) => ({
        label: parseInt(value, 10) ? 'Regular' : 'OPC',
        value,
      }));
    case 'inventory.car_type':
    case 'listing.body_type':
      return facetValues.map((value) => ({
        label: ['suv', 'mpv'].includes(value)
          ? upperCase(value.replace(/_/g, ' '))
          : startCase(value.replace(/_/g, ' ')),
        value,
      }));
    case 'inventory.wheel_drives':
      return facetValues.map((value) => ({
        label: toUpper(value),
        value,
      }));
    case 'inventory.transmission':
      return filterOptions;
    case 'location.name':
      return facetValues.map((value) => ({
        label: value.replace(/\w+/g, capitalize).replace(/-/g, ' ').replace('   ', '-'),
        value,
      }));
    case 'inventory.color':
      return facetValues.map(
        (value) =>
          filterOptions.find((item: { value: string }) => toLower(item.value) === toLower(value)) || {
            label: capitalize(value.replace(/_/g, ' ')),
            value,
          },
      );
    case 'inventory.fuel_type':
      return facetValues.map(
        (value) =>
          filterOptions.find((item: { value: string }) => toLower(item.value) === toLower(value)) || {
            label: capitalize(value.replace(/_/g, ' ')),
            value,
          },
      );
    default:
      return facetValues.map((value) => ({
        label: startCase(value.replace(/_/g, ' ')),
        value,
      }));
  }
};

/**
 * Sync algolia facets data into filter setting's options
 * based on algoliaFetching and requireDefault props
 *
 * @param filterSettings
 * @param facets
 * @param facetStats
 * @returns
 */
export const syncFacetsToFilterSettings = (
  filterSettings: SettingProps[],
  facets: Record<string, Record<string, number>>,
  facetStats: FacetStatsProps,
  defaultModels?: [] | undefined,
  // eslint-disable-next-line arrow-body-style
) => {
  return filterSettings.map((setting: SettingProps) => {
    // set facet stats at first load
    const isInitialLoad = setting.requireDefaults;
    let newFilters = [...(setting.filters || [])];
    if (isInitialLoad || setting.algoliaFetching) {
      if (isInitialLoad) {
        newFilters =
          setting.filters?.map((filter) => {
            const facetStat = facetStats?.[filter?.name as keyof typeof facetStats];
            if (facetStat) {
              return { ...filter, facetStats: facetStat };
            }

            return filter;
          }) || [];
      }
      if (setting.algoliaFetching && !setting.isFetched) {
        newFilters =
          newFilters.map((filter) => {
            if (['button', 'checkbox', 'select'].includes(filter?.type || '')) {
              const options = getOptionsInFacets(facets, filter?.name || '', filter.options);
              return { ...filter, options };
            }
            return filter;
          }) || [];
      }
    }

    // Check if the setting name is 'make_model'
    if (setting.name === 'make_model') {
      newFilters = newFilters.map((filter) => {
        // Check if the current filter has selected options
        if (filter.selectedOptions) {
          // Get options in the facets that match the current filter's name and options
          const optionsFromFacets = getOptionsInFacets(facets, filter?.name || '', filter.options);

          // Reduce the array of facets to an array of selected options that match the selected options in the filter
          // if defaultModels exist, use defaultModels
          const result = (defaultModels || optionsFromFacets).reduce(
            (acc: DefaultOptionProps[], facetMakeModel: DefaultOptionProps) => {
              // Find a matching car based on the label in the selected options and the facet
              const matchingCar = filter?.selectedOptions?.find((urlMakeModel) => {
                // Create an array of labels to compare, removing spaces and dashes and converting to uppercase
                const targetLabelArr = [urlMakeModel.label, urlMakeModel.customLabel]
                  .filter((i) => i)
                  .map((i) =>
                    String(i)
                      .replace(/[\s-]+/g, '')
                      .toUpperCase(),
                  );

                // Create an array of labels from the Algolia search results to compare
                const algoliaLabelArr = [facetMakeModel?.customLabel, facetMakeModel?.label]
                  .filter((i) => i)
                  .map((i) =>
                    String(i)
                      .replace(/[\s-]+/g, '')
                      .toUpperCase(),
                  ); // Remove spaces and dashes from the facet label

                // Check if any of the labels in the Algolia results match any of the labels to compare
                return algoliaLabelArr.some((e) => targetLabelArr.includes(e));
              });

              // If a matching car is found, modify the selected make/model and add it to the accumulator
              if (matchingCar) {
                const modifiedSelectedMakeModel = {
                  label: String(defaultModels ? facetMakeModel.customLabel : facetMakeModel.label).toUpperCase(),
                  value: facetMakeModel.value,
                  customLabel: String(defaultModels ? facetMakeModel.customLabel : facetMakeModel.label).toUpperCase(),
                };
                acc.push(modifiedSelectedMakeModel);
              }
              return acc;
            },
            [],
          );

          // Return the filter with the modified selected options
          return { ...filter, selectedOptions: result };
        }

        return filter;
      });
    }

    return { ...setting, filters: newFilters };
  });
};

export const getFilterSelectedOptions = (filterSettings: SettingProps[]) => {
  const result: QSUrlProps = {};
  filterSettings.forEach((setting: SettingProps) => {
    setting?.filters?.forEach((filter: FilterProps) => {
      if (hasSelectedValues(filter)) {
        const key = filter.name;
        type keyType = keyof typeof result;
        result[key as keyType] = {
          setting: setting.name,
          ...(filter.selectedMin && { min: filter.selectedMin.value }),
          ...(filter.selectedMax && { max: filter.selectedMax.value }),
          ...(filter.selectedOptions && {
            sv: filter.selectedOptions.map((opt: DefaultOptionProps) => opt.value).toString(),
          }),
          ...(filter.selectedMinInput && { minInput: filter.selectedMinInput }),
          ...(filter.selectedMaxInput && { maxInput: filter.selectedMaxInput }),
          ...(filter.selectedNumber && { num: filter.selectedNumber }),
          ...(filter.selectedBoolean && { bool: filter.selectedBoolean }),
        };
      }
    });
  });
  return result;
};

/**
 *
 * Convert query object into filter settings props
 *
 * @param qsObj
 * @param bodyType
 * @returns
 */
export const getPayloads = (qsObj: Record<string, any>, bodyType: any, country: string) => {
  let newQsObj = qs.parse(qs.stringify(qsObj)) || {};
  const payloads: any = [];
  const listFilterNameFromQS = mapFilterNameFromQS(country);

  const rangeFilterConfig = country === 'hk' ? RANGE_FILTER_CONFIG.hk : RANGE_FILTER_CONFIG.default;
  const buttonFilterConfig = country === 'hk' ? BUTTON_FILTER_CONFIG_HK : BUTTON_FILTER_CONFIG;

  // when the url format is: '/domain/buy/brandName' - ex: 'domain/buy/honda'
  if (!newQsObj.model && newQsObj.brand) {
    const key = get(listFilterNameFromQS, 'brands');
    payloads.push({
      settingName: key,
      filterName: get(buttonFilterConfig, `${key}.filterName`),
      selectedOptions: [decodeURIComponent(newQsObj.brand as string).toUpperCase()],
      isFromQS: true,
      header: '',
      name: get(buttonFilterConfig, `${key}.filterName`),
    });
    newQsObj = omit(newQsObj, 'brand');
  }

  // when the url format is: '/domain/buy/brandName/modelName' - ex: 'domain/buy/honda/honda-civic'
  if (newQsObj.model && newQsObj.brand) {
    const key = get(listFilterNameFromQS, 'models');
    payloads.push({
      settingName: key,
      filterName: get(buttonFilterConfig, `${key}.filterName`),
      selectedOptions: [`${(newQsObj.brand as string).toUpperCase()} ${(newQsObj.model as string).toUpperCase()}`],
      isFromQS: true,
      header: '',
      name: get(buttonFilterConfig, `${key}.filterName`),
    });
    newQsObj = omit(newQsObj, 'model');
  }

  Object.keys(newQsObj).forEach((qsKey: string) => {
    const key = get(listFilterNameFromQS, qsKey);
    if (rangeFilterConfig[key]) {
      const currentFilterValues = (get(newQsObj, qsKey) as string)?.split(',');
      if (currentFilterValues)
        payloads.push({
          settingName: key,
          filterName: rangeFilterConfig[key].filterName,
          selectedMinInput: currentFilterValues?.[0],
          selectedMaxInput: currentFilterValues?.[1],
          isFromQS: true,
          header: '',
          name: rangeFilterConfig[key].filterName,
        });
    }
    if (NUMBER_FILTER_CONFIG[key]) {
      const currentFilterValues = get(newQsObj, qsKey) as string;
      if (currentFilterValues)
        payloads.push({
          settingName: key,
          filterName: NUMBER_FILTER_CONFIG[key].filterName,
          selectedNumber: parseInt(currentFilterValues, 10),
          isFromQS: true,
          header: '',
          name: NUMBER_FILTER_CONFIG[key].filterName,
        });
    }
    if (buttonFilterConfig[key]) {
      let newCurrentFilterValues = [];
      const currentFilterValues = (get(newQsObj, qsKey) as string).split(',');

      if (key === 'tags' || key === 'availability_button') {
        const filterConfig =
          key === 'tags' ? CARRO_EXCLUSIVES_FILTER_URL_CONFIG[country] : AVAILABILITY_FILTER_URL_CONFIG[country];
        newCurrentFilterValues = currentFilterValues.map((value) => {
          const exclusiveFilter = filterConfig.find((config) => config.urlValue === value);
          return exclusiveFilter?.filterValue || value;
        });
      } else newCurrentFilterValues = currentFilterValues.map((value) => value);

      if (currentFilterValues)
        payloads.push({
          settingName: key,
          filterName: get(buttonFilterConfig, `${key}.filterName`),
          selectedOptions: newCurrentFilterValues,
          isFromQS: true,
          header: '',
          name: get(buttonFilterConfig, `${key}.filterName`),
        });
    }
    if (BOOLEAN_FILTER_CONFIG[key]) {
      const currentFilterValues = get(newQsObj, qsKey) as string;
      if (currentFilterValues)
        payloads.push({
          settingName: key,
          filterName: BOOLEAN_FILTER_CONFIG[key].filterName,
          selectedBoolean: Boolean(currentFilterValues),
          isFromQS: true,
          header: '',
          name: BOOLEAN_FILTER_CONFIG[key].filterName,
        });
    }
    // if (CHECK_BOX_FILTER_CONFIG[key]) {
    //   const currentFilterValues = (get(newQsObj, qsKey) as string).split(',');
    //   if (currentFilterValues)
    //     payloads.push({
    //       settingName: key,
    //       filterName: CHECK_BOX_FILTER_CONFIG[key].filterName,
    //       selectedOptions: currentFilterValues,
    //       isFromQS: true,
    //       header: '',
    //       name: CHECK_BOX_FILTER_CONFIG[key].filterName,
    //     });
    // }
  });

  // set bodyType from router's query to filter options
  if (bodyType) {
    const optionNameByType = {
      hatchback: 'hatchback',
      sedan: 'mid_sized_sedan',
      mpv: 'mpv',
      suv: 'suv',
    };
    const optionName = optionNameByType[bodyType as keyof typeof optionNameByType];
    const bodyTypeFilter = payloads.find((item: any) =>
      ['inventory.car_type', 'listing.body_type'].includes(item.filterName),
    );
    if (bodyTypeFilter && optionName) {
      bodyTypeFilter.selectedOptions = [...Array.from(new Set(bodyTypeFilter.selectedOptions.concat(optionName)))];
    } else {
      payloads.push({
        header: 'buy.filter_label.body',
        isFromQS: true,
        name: country === 'my' ? 'listing.body_type' : 'inventory.car_type',
        selectedMax: undefined,
        selectedMin: undefined,
        selectedOptions: [optionName],
        settingName: undefined,
        filterName: '',
        selectedNumber: 0,
        selectedMinInput: undefined,
        selectedMaxInput: undefined,
        selectedBoolean: undefined,
      });
    }
  }

  return payloads;
};

/**
 *
 * Convert query string to our current filter settings
 *
 * @param settings
 * @param payloads
 * @returns
 */
export const mapFilterFromQSToCurrentFilterSettings = (settings: SettingProps[], payloads: QSFilterProps[]) => {
  let newSettings = [...settings];
  payloads.forEach((payload: QSFilterProps) => {
    const {
      filterName,
      selectedOptions,
      selectedMin,
      selectedMax,
      selectedBoolean,
      selectedMinInput,
      selectedMaxInput,
      selectedNumber,
    } = payload;

    newSettings = newSettings.map((s: SettingProps) => {
      if (s.name === payload.settingName) {
        let newFilters = [...(s.filters || [])];
        newFilters = newFilters.map((f) => {
          if (payload.filterName === 'listing.is360view' && f.type === 'boolean') {
            const newFilter = { ...f };
            newFilter.selectedBoolean = true;
            return newFilter;
          }

          if (f.name === payload.filterName || f.customName === payload.filterName) {
            const newFilter = { ...f };
            const {
              type,
              options,
              optionsMin,
              optionsMax,
              selectedCustomLabel,
              facetStats,
              requireCommaFormat,
              displayMultiplier,
            } = f;

            const { min, max } = facetStats || {};
            const newSelectedMin = selectedMin ? optionsMin?.find((opt) => opt.value === selectedMin) : null;
            const newSelectedMax = selectedMax ? optionsMax?.find((opt) => opt.value === selectedMax) : null;
            let newSelectedOptions = null;

            if (options) {
              newSelectedOptions = selectedOptions
                ? compact(
                    selectedOptions.map((sOpt: string | number) =>
                      // Allow compare string and number
                      // eslint-disable-next-line eqeqeq
                      options.find((opt: DefaultOptionProps) => opt.value == sOpt),
                    ),
                  )
                : null;
            } else if (f.name === 'inventory.make_model' && f.selectedOptions) {
              newSelectedOptions = f.selectedOptions;
            } else {
              newSelectedOptions =
                selectedOptions?.map((sOpt: string | number) => ({ label: sOpt, value: sOpt })) || null;
            }

            if (type === 'button' && f?.selectedCustomLabel) {
              newSelectedOptions = newSelectedOptions?.map((newOpt: DefaultOptionProps) => ({
                ...newOpt,
                customLabel: getCustomLabel(i18next.t(selectedCustomLabel || ''), newOpt),
              }));
            }

            if (type === 'button' && s.name === 'make') {
              newSelectedOptions = uniqBy(newSelectedOptions, (o) =>
                typeof o.value === 'string' ? upperCase(o.value) : o.value,
              );
              newSelectedOptions = newSelectedOptions?.map(
                (newOpt: DefaultOptionProps) =>
                  ({
                    ...newOpt,
                    name: 'make',
                    filterName: 'inventory.make',
                    customLabel: typeof newOpt.value === 'string' ? upperCase(newOpt.value) : newOpt.value,
                    make: newOpt.value,
                  } as DefaultOptionProps),
              );
            }

            if (type === 'number') {
              newSelectedOptions = [
                {
                  label: filterName,
                  value: selectedNumber,
                  customLabel: getCustomLabel(i18next.t(selectedCustomLabel || '') || filterName, payload) || f.header,
                },
              ];
            }
            if (type === 'range_input') {
              const newSelectedMinInput = selectedMinInput || min || 0;
              const newSelectedMaxInput = selectedMaxInput || max || 0;

              newSelectedOptions = [
                {
                  label: filterName,
                  value: filterName,
                  customLabel:
                    getCustomLabel(i18next.t(selectedCustomLabel || '') || filterName, {
                      ...payload,
                      selectedMinInput: formatNumber(newSelectedMinInput, requireCommaFormat, displayMultiplier),
                      selectedMaxInput: formatNumber(newSelectedMaxInput, requireCommaFormat, displayMultiplier),
                    }) || f.header,
                },
              ];
            }

            switch (type) {
              case 'range':
                newFilter.selectedMin = newSelectedMin;
                newFilter.selectedMax = newSelectedMax;
                break;
              case 'button':
              case 'checkbox':
              case 'select':
              case 'make_model':
                // newFilter.currentFilterHeader = header;
                newFilter.selectedOptions = newSelectedOptions;
                break;
              case 'boolean':
                newFilter.selectedBoolean = selectedBoolean;
                break;
              case 'number':
                newFilter.selectedNumber = selectedNumber;
                newFilter.selectedOptions = newSelectedOptions;
                break;
              case 'range_input':
                newFilter.selectedMinInput = selectedMinInput;
                newFilter.selectedMaxInput = selectedMaxInput;
                newFilter.selectedOptions = newSelectedOptions;
                break;
              default:
                break;
            }

            return newFilter;
          }

          return f;
        });

        return { ...s, filters: newFilters };
      }

      return s;
    });
  });

  return newSettings;
};

/**
 * Get new current settings after fetch options from algolia
 *
 * @param currentSettings
 * @param filterConfig
 * @param fetchAlgoliaData
 * @returns
 */
export const fetchOptionsFromAlgolia = async (
  currentSettings: SettingProps[],
  filterConfig: FilterConfigProps,
  fetchAlgoliaData: (params: any) => any,
  defaultModels?: [] | undefined,
) => {
  const params = {
    page: 0, // since algolia Page numbers are zero-based
    facets: ['*'],
    filters: '',
    hitsPerPage: filterConfig.hitsPerPage,
    attributesToRetrieve: filterConfig.attributesToRetrieve,
  };

  const res = await fetchAlgoliaData(params);
  const facets = res?.facets;
  const facetStats = res?.facets_stats;

  const newFilterSettings = syncFacetsToFilterSettings(currentSettings, facets || {}, facetStats || {}, defaultModels);

  return { newFilterSettings, facets, facetStats };
};

export const syncCarroExclusiveIntoFilters = (
  newFilterSettings: any[],
  carroExclusiveData: CarroExclusivesTag[],
  country: string,
): SettingProps[] => {
  const isTH = country === 'th';

  return newFilterSettings.map((s) => {
    if (s.name === 'tags') {
      // eslint-disable-next-line no-param-reassign
      s.filters = (s.filters as any[]).map((f) => {
        if (f.name === (isTH ? 'flags' : 'listing')) {
          const newOptions = carroExclusiveData.map((excl) => ({
            customValue: excl.algolia_key,
            value: excl.display_name,
            label: excl.display_name,
            name: excl.display_name,
            urlValue: excl.display_name,
            filterValue: excl.display_name,
          }));

          return { ...f, options: newOptions };
        }

        return f;
      });
    }

    return s;
  });
};

/**
 * Get formatted make options from string array
 * eg: ['Honda'] => [{name: 'make', make: 'Honda, ...}]
 *
 * @param makes
 * @returns
 */
export const fmMakeOptionsFromStringArray = (makes: string[]) =>
  makes?.map(
    (m) =>
      ({
        name: 'make',
        filterName: 'inventory.make',
        label: m,
        value: m,
        customLabel: m,
        make: m,
      } as DefaultOptionProps),
  );

/**
 * Get string array from formatted make options
 * eg: ['Honda'] => [{name: 'make', make: 'Honda, ...}]
 *
 * @param makes
 * @returns
 */
export const fmStringArrayFromMakeOptions = (makes: DefaultOptionProps[]) => map(makes, 'value');

/**
 * Get new tag label
 *
 * @param filter
 * @param item
 * @param t
 * @returns
 */
export const getNewTagLabel = (filter: FilterProps | BuyHuntFilterProps, item: DefaultOptionProps, t: any) => {
  let newFilter;
  const {
    type,
    selectedCustomLabel,
    facetStats,
    selectedMinInput,
    selectedMaxInput,
    requireCommaFormat,
    displayMultiplier,
  } = filter;

  const { min, max } = facetStats || {};

  const newSelectedMinInput = selectedMinInput || min || 0;
  const newSelectedMaxInput = selectedMaxInput || max || 0;

  switch (type) {
    case 'range_input':
      newFilter = {
        ...filter,
        selectedMinInput: formatNumber(newSelectedMinInput, requireCommaFormat, displayMultiplier),
        selectedMaxInput: formatNumber(newSelectedMaxInput, requireCommaFormat, displayMultiplier),
      };
      break;
    default:
      newFilter = filter;
      break;
  }

  // Get label based on selectedCustomLabel from filter setting or each selectedOptions item
  let result;

  if (selectedCustomLabel) {
    const translated = t(selectedCustomLabel || '');
    result = getCustomLabel(translated, newFilter);
    if (typeof result === 'string' && result.includes('undefined')) {
      result = getCustomLabel(translated, item);
    }
  }

  if (!result)
    result = (isDotNotation(item?.customLabel) ? t(item.customLabel) : item?.customLabel) ?? t(item.label as string);
  return result;
};

const formatModelOptions = (make: string, results: any) =>
  results?.['inventory.model']?.map((model: string) => {
    // find make_model returned from Algolia by string comparison
    // Some special case are covered:
    // eg:
    // makeModelOpt: Toyota Corolla Altis, Toyota Corolla;
    // model: Corolla; Corolla Altis
    // makeModelOpt: Subaru Subaru, SUBARU OUTBACK, SUBARU BRZ
    // model: Subaru
    const makeModelExpected = toUpper(`${make} ${model}`);

    const makeModel = results?.['inventory.make_model']?.filter(
      (makeModelOpt: any) =>
        includes(toUpper(makeModelOpt), makeModelExpected) || includes(toUpper(makeModelOpt), toUpper(model)),
    );

    const makeModelString =
      makeModel.find((mm: any) => toUpper(mm) === toUpper(makeModelExpected)) ||
      (closestMatch(makeModelExpected, makeModel) as string) ||
      (closestMatch(model, makeModel) as string);

    return {
      label: model,
      value: toUpper(makeModelString) || toUpper(model),
      customLabel: makeModelString || model,
      make,
    };
  }) || [];

export const fetchMakeModelOptions = async (make: string, fetchMakesModels: (params: any) => Promise<unknown>) => {
  const fetchedOptions = await fetchMakesModels({
    filters: `inventory.make: "${make}"`,
    facetsToRetrieve: ['inventory.model', 'inventory.make_model'],
  });
  return formatModelOptions(make, fetchedOptions);
};

export const fillMissingMakeModelOptions = async (
  settings: SettingProps[],
  fetchMakesModels: (params: any) => Promise<Record<string, any>>,
) => {
  const newSettings = deepCopy(settings);
  const makeSettings = newSettings.find((s: SettingProps) => s.name === 'make');
  const makeModelSettings = newSettings.find((s: SettingProps) => s.name === 'make_model');
  const makeModelSettingsIndex = newSettings.findIndex((s: SettingProps) => s.name === 'make_model');

  const makeModelSelectedOptions = get(makeModelSettings, 'filters.[0].selectedOptions');
  const makeSelectedOptions = get(makeSettings, 'filters.[0].selectedOptions') as DefaultOptionProps[];

  if (!isEmpty(makeSelectedOptions)) {
    const missingMakes = !isEmpty(makeModelSelectedOptions)
      ? makeSelectedOptions
          .map((m) => {
            const foundMakeInMakeModelOptions = makeModelSelectedOptions?.some((o: DefaultOptionProps) =>
              o?.customLabel?.includes(m?.customLabel || ''),
            );
            return !foundMakeInMakeModelOptions ? m : null;
          })
          .filter((m) => m)
      : makeSelectedOptions;

    if (!isEmpty(missingMakes)) {
      let newMakeModelSelectedOptions = await Promise.all(
        missingMakes.map(async (m) => {
          const options = await fetchMakeModelOptions(m?.make || m?.value, fetchMakesModels);
          return [...options];
        }),
      );

      newMakeModelSelectedOptions = flatten(newMakeModelSelectedOptions);
      newMakeModelSelectedOptions = unionBy(newMakeModelSelectedOptions, makeModelSelectedOptions, 'value');

      set(newSettings, `[${makeModelSettingsIndex}].filters.[0].selectedOptions`, newMakeModelSelectedOptions);
    }
  }

  return newSettings;
};

export const getFilterSelectedOptionsV2 = (filterSettings: SettingProps[], countryCode: string) => {
  const result = {};
  const rangeFilterConfig = countryCode === 'hk' ? RANGE_FILTER_CONFIG.hk : RANGE_FILTER_CONFIG.default;
  filterSettings.forEach((setting: SettingProps) => {
    setting?.filters?.forEach((filter: FilterProps) => {
      if (hasSelectedValues(filter)) {
        const filterConfigs = {
          ...rangeFilterConfig,
          ...NUMBER_FILTER_CONFIG,
          ...BUTTON_FILTER_CONFIG,
          ...BOOLEAN_FILTER_CONFIG,
          // ...CHECK_BOX_FILTER_CONFIG,
        };
        const key = get(filterConfigs, `${setting.name}`).urlName;
        const { type } = filter;

        switch (type) {
          case 'range_input':
            set(result, key, [filter.selectedMinInput, filter.selectedMaxInput]);
            break;

          case 'number':
            set(result, key, filter.selectedNumber);
            break;

          case 'boolean':
            if (key === '360view') {
              set(result, key, 1);
            } else {
              set(result, key, filter.selectedBoolean);
            }
            break;

          case 'checkbox':
            set(
              result,
              key,
              filter.selectedOptions?.map((opt) => opt.value),
            );
            break;

          case 'make_model':
          case 'button':
            if (key === 'availability') {
              const newSelectedOptions = filter.selectedOptions?.map((opt: DefaultOptionProps) => {
                const value = AVAILABILITY_FILTER_URL_CONFIG[countryCode.toLowerCase()].find(
                  (config) => config.name === opt.value,
                );
                return value?.urlValue;
              });
              set(result, key, newSelectedOptions?.toString());
            } else if (key === 'exclusives') {
              const newSelectedOptions = filter.selectedOptions?.map((opt: DefaultOptionProps) => {
                // const value = CARRO_EXCLUSIVES_FILTER_URL_CONFIG[countryCode.toLowerCase()].find(
                //   (config) => config.name === opt.name,
                // );
                // return value?.urlValue;
                return opt?.urlValue;
              });
              set(result, key, newSelectedOptions?.toString());
            } else
              set(
                result,
                key,
                filter.selectedOptions
                  ?.map((opt: DefaultOptionProps) => (opt.name === 'listing.is_360' ? 'is360View' : opt.value))
                  .toString(),
              );
            break;

          default:
            break;
        }
      }
    });
  });

  return result;
};

/**
 * Parses an `asPath` string into a structured object if `routerQuery` is empty or not provided.
 * Otherwise, returns `routerQuery` as-is.
 *
 * @param {string} asPath - The `asPath` string to parse.
 * @param routerQuery - The `routerQuery` object to return if not empty.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseRouterQueryFromAsPath = (asPath: string, routerQuery: any) => {
  if (!isEmpty(asPath)) {
    let parsedQuery = routerQuery;
    const parts = asPath.split('/').filter(Boolean);
    const lang = parts[0];
    let brand;
    let model;

    if (parts.length > 1 && ['buy', 'beli-mobil-bekas', 'beli-kereta-terpakai'].includes(parts[1])) {
      brand = parts?.[2];
      model = parts?.[3];

      if (brand && brand?.indexOf('?') !== -1) {
        // If the brand contains a query string, remove it
        brand = brand.slice(0, brand.indexOf('?'));
      }

      if (model && model.indexOf('?') !== -1) {
        // If the model contains a query string, remove it
        model = model.slice(0, model.indexOf('?'));
      }
    }

    if (brand && !routerQuery?.brand) {
      parsedQuery = { ...parsedQuery, lang, brand, model };
    }

    return parsedQuery;
  }

  // If `routerQuery` is not empty or `asPath` is empty, return `routerQuery` as-is
  return routerQuery;
};
